var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"box"},[_vm._m(0),_c('div',{staticClass:"topic"},_vm._l((_vm.registerList),function(ite,i){return _c('div',{staticClass:"item cursor",class:{'active':_vm.active==i},on:{"click":function($event){return _vm.changeActive(i)}}},[_c('p',[_vm._v(_vm._s(ite.name))])])}),0),(_vm.active==0)?_c('div',{staticClass:"platform"},[_c('homeTitlepc',{attrs:{"label":"平台介绍"}}),_vm._m(1)],1):_vm._e(),(_vm.active==0)?_c('div',{staticClass:"company"},[_c('homeTitlepc',{attrs:{"label":"公司介绍"}}),_vm._m(2)],1):_vm._e(),(_vm.active==1)?_c('div',{staticClass:"cooperate"},[_c('homeTitlepc',{attrs:{"label":"合作伙伴"}}),_vm._m(3),_vm._m(4),_vm._m(5)],1):_vm._e(),(_vm.active==2)?_c('div',{staticClass:"contact"},[_c('homeTitlepc',{attrs:{"label":"联系我们"}}),_vm._m(6)],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("./images/banner.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sections"},[_c('div',{staticClass:"part"},[_vm._v("平台定位：识权识美平台充分结合文创产业人才文化创新性、高知识性、丰富的想象力、高流动性和独特思维方式的特点， 运用人力资源创新模式，整合互联网新技术形态以“公证服务 +区块链+知识产权交易” 的服务方式，实现原创作品知识产权 的保护及交易，并推动中国文化的发展。")]),_c('img',{attrs:{"src":require("./images/platform.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sections"},[_c('div',{staticClass:"part"},[_vm._v("原创智汇（广东）信息科技有限公司，成立于2020年12月17日，注册资本为500万元人民币，是一家以信息科技融入知识产权产业，为用户提供以公证服务、维权服务、知产配套服务等为服务核心，致力于保护企业及原创作者知识产权的合法权益，促进知识产权实现价值转化的科技型企业。")]),_c('div',{staticClass:"parts"},[_vm._v("原创智汇主营产品为识权识美平台，成功打通了人力资源、企业服务和知识产权产业之间的壁垒，着力解决知识产权在确权、维权及用权三大环节上“闭环难保证”、“维权渠道窄”等问题，为用户提供公证服务、维权服务、知产配套服务、知产交易服务等，致力于保护原创作者、企业的合法权益，同时国内众多优秀的创作设计者可以通过平台进行业务合作、商务接单等，为创作设计者与企业之间提供一种新型的合伙关系，有助于赋能知识产权产业与人力资源行业的融合。")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cooperate-info"},[_c('img',{attrs:{"src":require("./images/logo2.png")}}),_c('p',[_vm._v("广州市南沙公证处")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cooperate-info"},[_c('img',{attrs:{"src":require("./images/logo3.png")}}),_c('p',[_vm._v("深圳市福田公证处")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cooperate-info"},[_c('img',{attrs:{"src":require("./images/logo4.png")}}),_c('p',[_vm._v("广州越富嘉驰网络科技有限公司")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contact-info"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./images/p5.png")}}),_c('div',{staticClass:"title"},[_vm._v("原创智汇（广东）信息科技有限公司")]),_c('div',{staticClass:"explain"},[_c('div',{staticClass:"explain-info"},[_c('img',{attrs:{"src":require("./images/p7.png")}}),_c('p',[_vm._v("公司地址：广州市黄浦区南云一路2号2栋316")])]),_c('div',{staticClass:"explain-info"},[_c('img',{attrs:{"src":require("./images/p8.png")}}),_c('p',[_vm._v("联系电话：020-83824711")])])])])])
}]

export { render, staticRenderFns }